import { MaterielCategoryResponse, PrestationModel } from '@/models/prescriptions-model'
import { Component, Vue, Prop } from 'vue-property-decorator'
import EmptyTable from '@/components/shared/EmptyTable.vue'
import Prestation from '@/views/Patients/Prescriptions/Prestation/Prestation.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { AxiosResponse } from 'axios'
import { ErrorService } from '@/services/error.service'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { Subscription } from 'rxjs'
import { getPrestationCategoryName } from '../../Dossier/Constants'
import PrestationPrescriptionTypeId from '@/components/shared/TableCellRenderer/PrestationPrescriptionTypeId.vue'
import Commons from '@/components/shared/Helpers/commons'
import PrescriptionHelpers from '@/components/shared/Helpers/prescription-helpers'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    EmptyTable,
    Prestation,
    Confirm,
    Alert,
    PrestationPrescriptionTypeId
  }
})
export default class Prestations extends Vue {
  @Prop()
  public prescriptionIdProp!: string

  private prescriptionService = PatientPrescriptionService.getInstance()
  private subscription: Subscription[] = []
  private prescriptionId!: string
  public selectedPrestation: PrestationModel = { prescriptionTypeId: 1 }
  public prestations: PrestationModel[] = []
  public errorMessages: string[] = []
  public showConfirm = false
  public showDialog = false
  public isPrestationLoading = false
  public prestationsHeaders: any[] = []
  public materielCategories: MaterielCategoryResponse[] = []
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.prestationsHeaders = [
      {
        text: 'Prestation',
        value: 'categeoryTypeId',
        width: '25%',
        sort: function (a, b) {
          return that.getPrestationCategoryName(a)!.localeCompare(that.getPrestationCategoryName(b)!)
        }
      },
      {
        text: 'Début',
        value: 'dateStart',
        width: '14%'
      },
      {
        text: 'Échéance',
        value: 'dateEnd',
        width: '14%'
      },
      {
        text: 'Type',
        value: 'prescriptionTypeId',
        sortable: false,
        align: 'center',
        width: '10%'
      },
      {
        text: 'À renouveler',
        value: 'isRenewable',
        sortable: false,
        align: 'center',
        width: '12%'
      },
      {
        text: 'Renouvelée',
        value: 'renewed',
        sortable: false,
        width: '10%'
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '15%'
      }
    ]
    this.prescriptionId = this.prescriptionIdProp || this.$route.params.id
    this.subscription.push(this.prescriptionService.prestationDialogModel$.subscribe(model => {
      this.showDialog = model
    }))
    this.subscription.push(this.prescriptionService.prescriptionSelected$.subscribe(prescriptionRow => {
      if (prescriptionRow.date) {
        this.selectedPrestation.dateStart = prescriptionRow.date.substr(0, 10)
      }
      this.showDialog = prescriptionRow.id === this.prescriptionId && this.showDialog
    }))
    if (this.prescriptionId !== 'new') this.getPrestations()
    this.materielCategories = this.prescriptionService.categories
  }

  public getPrestationCategoryName (id: number) {
    return getPrestationCategoryName(id)
  }

  public canBeRenewed (prestation: PrestationModel) {
    return Commons.canPrestationBeRenewed(prestation)
  }

  public getPrestations () {
    this.isPrestationLoading = true
    this.prescriptionService.GetPrescriptionPrestations(this.prescriptionId)
      .then(response => {
        this.prestations = response as PrestationModel[]
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isPrestationLoading = false
      })
  }

  public async savePrestation () {
    this.isPrestationLoading = true
    const res = await this.prescriptionService.AddUpdatePrestation(this.prescriptionId, this.selectedPrestation)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isPrestationLoading = false
        this.closeDialog()
      })
    if ((res as AxiosResponse<PrestationModel>)?.status === 200) {
      this.getPrestations()
    }
  }

  public closeDialog () {
    this.selectedPrestation = { prescriptionTypeId: 1 }
    this.prescriptionService.updateprestationDialogModelSource(false)
    this.getPrestations()
  }

  public editPrestation (prestationItem: PrestationModel) {
    this.selectedPrestation = prestationItem
    this.showDialog = true
  }

  public displayDeleteConfirmDialog (prestationItem: PrestationModel) {
    this.showConfirm = true
    this.selectedPrestation = prestationItem
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.prescriptionService.deletePrescriptionPrestation(this.selectedPrestation.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })

      if ((results as AxiosResponse<number>)?.status === 200) {
        this.errorMessages = []
        this.selectedPrestation = {}
        this.getPrestations()
      }
    }
  }

  public countDistinctCategories (materielIds: number[]) {
    const descriptions = new Set(this.materielCategories.filter(m => materielIds!.some(x => m.materielIds.find(y => y === x))).map(m => m.description))
    return descriptions.size
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public destroyed () {
    this.subscription.forEach(sub => sub.unsubscribe())
    this.prescriptionService.updateprestationDialogModelSource(false)
  }

  public getPrestationReports (prestationItem: PrestationModel) {
    this.$router.push(`/patient/prestation/${prestationItem.id}/reports`)
  }

  public generateMaterielPDFReport (prestationItem: PrestationModel) {
    this.prescriptionService.updatePrestationSelected(prestationItem)
    this.$router.push({ name: 'MaterielReport', params: { id: prestationItem.id! } })
  }

  public generateCourseGroupPDFReport (prestationItem: PrestationModel) {
    this.prescriptionService.updatePrestationSelected(prestationItem)
    this.$router.push({ name: 'CourseGroupReport', params: { id: prestationItem.id! } })
  }

  public renewedState (prestationItem: PrestationModel) {
    return PrescriptionHelpers.GetRenewedState(prestationItem.isRenewed!)
  }
}
