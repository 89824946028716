














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EmptyTable extends Vue {
  @Prop()
  public isLoading!: boolean

  public emitRefreshClick () {
    this.$emit('refresh-click')
  }
}
