var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"embeded-table"},[_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer cette prestation","message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer cette prestation ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),_c('alert',{attrs:{"type":"error","canClose":true,"value":_vm.errorMessages.length > 0},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),_c('c-data-table',{attrs:{"headers":_vm.prestationsHeaders,"items":_vm.prestations,"hide-default-footer":_vm.prestations.length <= 10,"sort-by":['category', 'endDate'],"mobile-breakpoint":600,"loading":_vm.isPrestationLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isPrestationLoading }},scopedSlots:_vm._u([{key:"item.categeoryTypeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPrestationCategoryName(item.categeoryTypeId))+" "),(item.materielCategoriesIds.length)?_c('v-badge',{attrs:{"bottom":"","bordered":"","inline":"","content":_vm.countDistinctCategories(item.materielCategoriesIds)}}):_vm._e()]}},{key:"item.dateEnd",fn:function(ref){
var item = ref.item;
return [_c('em',[_vm._v(_vm._s(_vm._f("date-fr")(item.dateEnd)))])]}},{key:"item.dateStart",fn:function(ref){
var item = ref.item;
return [_c('em',[_vm._v(_vm._s(_vm._f("date-fr")(item.dateStart)))])]}},{key:"item.prescriptionTypeId",fn:function(ref){
var item = ref.item;
return [_c('PrestationPrescriptionTypeId',{attrs:{"item":item}})]}},{key:"item.renewed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.renewedState(item)))])]}},{key:"item.isRenewable",fn:function(ref){
var item = ref.item;
return [(item.isRenewable && _vm.canBeRenewed(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-and-label"},'div',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-restart ")]),_c('em',{staticClass:"d-sm-none"},[_vm._v("À renouveler")])],1)]}}],null,true)},[_c('span',[_vm._v("À renouveler")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.categeoryTypeId === 6)?_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc",on:{"click":function($event){return _vm.generateMaterielPDFReport(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Prescription médicale pour les moyens et appareils (LIMA)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.categeoryTypeId === 7)?_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc",on:{"click":function($event){return _vm.generateCourseGroupPDFReport(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Prescription médicale pour les cours et groupes")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc",on:{"click":function($event){return _vm.getPrestationReports(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-outline ")])]}}],null,true)},[_c('span',[_vm._v("Rapports")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editPrestation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),(!item.isLinkedToInvoice)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('empty-table',{attrs:{"isLoading":_vm.isPrestationLoading},on:{"refresh-click":function($event){return _vm.getPrestations()}}})]},proxy:true}],null,true)}),(_vm.showDialog)?_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('prestation',{attrs:{"prestation":_vm.selectedPrestation,"materiels":_vm.materielCategories},on:{"save-click":_vm.savePrestation,"close-click":_vm.closeDialog,"delete-click":_vm.displayDeleteConfirmDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }