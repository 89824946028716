import Commons from '@/components/shared/Helpers/commons'
import { MaterielCategoryResponse, PrestationModel } from '@/models/prescriptions-model'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { prescriptionTypes, prestationCategories, PrestationDiabetique, PrestationDietetique } from '../../Dossier/Constants'
import { isRenewedFlag, isRenewedFlagPending } from '@/views/Prescriptions/Constants'
import { RenewablePrestationConsultationCategeoryTypeIds } from '@/models/static'

@Component({
  components: {
    ValidationObserver
  }
})
export default class Prestation extends Vue {
  @Prop()
  public prestation!: PrestationModel

  @Prop()
  public materiels!: MaterielCategoryResponse[]

  public selection: string[] = []
  public prestationCategories = prestationCategories
  public prestationTypes = prescriptionTypes
  public isRenewedFlag = isRenewedFlag

  public mounted () {
    if (this.prestation.materielCategoriesIds) {
      this.selection = this.materiels.filter(m => this.prestation.materielCategoriesIds!.some(x => m.materielIds.find(y => y === x))).map(m => m.description)
    } else {
      this.selection = []
    }
  }

  @Watch('prestation.dateStart')
  @Watch('prestation.categeoryTypeId')
  public getNextDate () {
    if (!this.prestation.dateStart || !this.prestation.categeoryTypeId) return
    const date = new Date(this.prestation.dateStart)
    const d = date.getDate()
    if (RenewablePrestationConsultationCategeoryTypeIds.includes(this.prestation.categeoryTypeId)) {
      date.setUTCMonth(date.getMonth() + 6)
    } else if (this.prestation.categeoryTypeId === 6) {
      date.setMonth(date.getMonth() + 12)
    } else {
      this.prestation.dateEnd = null
      return
    }
    if (date.getDate() !== d) {
      date.setDate(0)
    }
    this.prestation.dateEnd = date.toISOString().substr(0, 10)
  }

  public get canBeRenewed () {
    return Commons.canPrestationBeRenewed(this.prestation)
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.prestation.materielCategoriesIds = this.materiels.filter(x => this.selection.find(y => y === x.description)).flatMap(x => x.materielIds)
      this.$emit('save-click')
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public close () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.$emit('close-click')
  }

  public canEditRenewedFlag () {
    return this.prestation.isRenewed === isRenewedFlagPending
  }

  public get isEditMode () {
    return !!this.prestation.id
  }

  get isDietOrDiab () {
    return [PrestationDietetique, PrestationDiabetique].includes(this.prestation.categeoryTypeId as number)
  }
}
